.InvoiceCar input{
    background: rgb(255, 255, 255);
    border: solid;
    border-color: rgb(20, 60, 145);
    border-width: 1px;
    border-radius: 5px;
    height: 20px;
    margin: 0;
    margin-right: 5px;
    font-weight: 600;
    outline: 0;
    margin-bottom: 10px;
}

.InvoiceCar h4 {
    margin: 0;
}

.CarPropertiesWrapper {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.ActiveCarsWrapper {
    display: grid;
    grid-template-columns: 100px auto;
    margin-bottom: 30px;
    max-height: 100px;
    overflow: scroll;
}

.ActiveCars {
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px 120px 120px;
}
