::-webkit-scrollbar {
    display: none;
}

.App {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 50px 1fr;
    overflow: hidden;
    font-family: 'Roboto', serif;
}

.Content {
    display: grid;
    grid-template-columns: 26% 1fr 20%;
    overflow: hidden;
}

.header {
    background-color: rgb(20, 60, 145);
    background-size: cover;

    display: flex;
    flex-direction: row;
    align-items: center;
}

#title {
    color: white;
    margin-left: 10px
}

#title::selection {
    background: none;
}

#title:hover {
    cursor: default;
}


h1::selection {
    background: none;
}

h1:hover {
    cursor: default;
}

.loggedIn {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: 10px
}

.user-icon {
    display: flex;
    flex-direction: row;
    color: white;
}

#user-circle {
    margin: auto 10px auto 10px
}

#user-name {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
    font-weight: 550;
}





