.InvoiceInformation {
    display: grid;
    grid-template-rows: 40px 140px auto 140px 35px;
}

#invoice-information-title {
    border-radius: 10px 10px 0 0;
    background-color: rgb(20, 60, 145);
    background-size: cover;
    color: white;
    margin: 0;
    padding-top: 5px;
    padding-left: 10px;
}

.InvoiceInformation h3 {
    margin: 0;
}

.InvoiceInformation p {
    font-size: 15px;
    margin: 0;
}

.InvoiceInformationCar {
    display: grid;
    grid-template-rows: 40px;
    padding-left: 5px;
    padding-right: 5px;
}

.InvoiceInformationCar h3 {
    margin-top: 10px;
}

.CarPropertiesWrapper {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.CarPropertiesWrapper p {
    margin-bottom: 10px;
}

.InvoicePositionsWrapper {
    padding-left: 5px;
    padding-right: 5px;
}

.InvoicePositions {
    margin-top: 15px;
}

.InvoiceInformationPositions {
    border-bottom: solid;
    border-width: 1px;
    max-height: 300px;
    overflow: scroll;
}

.PositionProperties {
    display: grid;
    grid-template-columns: 40px 500px 100px 100px 100px 100px;
    grid-template-rows: 25px;
}

.PositionInformation {
    display: grid;
    grid-template-columns: 40px 500px 100px 100px 100px 100px;
}

.SalaryInvoiceInformationTotalWrapper {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px;
}

.invoice-information-total {
    justify-content: right;
    text-align: right;
}

.InvoiceInformationTotalAttributes {
    display: grid;
    grid-template-columns: auto 110px;
}

#amount, #price, #discount {
    text-align: right;
    margin-right: 5px;
}

.WageInformation {
    display: grid;
    grid-template-rows: 25px 25px 25px;
    grid-template-columns: 250px 100px 100px;
}

#printer {
    width: 40px;
}

#total {
    text-align: right;
}

