.Wage {
    display: grid;
    grid-template-rows: 25px 25px 50px 20px;
}

.Wage input {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(20, 60, 145);
    border-radius: 5px;
    height: 20px;
    margin: 0 5px 0 0;
    padding: 0;
    font-weight: 600;
    outline: 0;
}

.Wage h3, h4 {
    margin: 0;
}

.WageProperties {
    display: grid;
    grid-template-columns: 200px 100px 100px;
}

.WageInput {
    display: grid;
    grid-template-columns: 200px 100px 100px;
    grid-template-rows: 25px 25px;
}

.TotalWage {
    display: grid;
    grid-template-columns: 100px auto;
}

#totalWage, #displayTotalWage {
    text-align: left;
}

#wage-amount {
    text-align: center;
}

#wage-price {
    text-align: center;
}

#wage1, #wage2 {
    padding-left: 2px;
}

#amount1, #amount2, #price1, #price2 {
    text-align: right;
    padding-right: 2px;
}
