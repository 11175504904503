.Invoices {
    overflow: hidden;
}
h1 {
    margin-left: 10px;
}

.new {
    margin: 0;
}

#new-invoice-button {
    width: 200px;
    height: 30px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
    color: rgb(20, 60, 145);
    border: solid;
    border-color: rgb(20, 60, 145);
    border-width: 1px;
    border-radius: 5px;
    transition: transform;
    transition-duration: 0.3s;
}

#new-invoice-button:hover {
    transform: scale(1.05);
    background-color: rgb(20, 60, 145);
    color: white;
}

#new-invoice-button:focus {
    outline:0;
}

#select-customer-button {
    width: 200px;
    height: 30px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
    color: red;
    border: solid;
    border-color: red;
    border-width: 1px;
    border-radius: 5px;
    transition: transform;
    transition-duration: 0.3s;
}

#select-customer-button:focus {
    outline:0;
}
