.Invoice {
    display: grid;
    grid-template-columns: 145px 100px 150px 75px auto;
    border-bottom: solid;
    border-color: rgb(212, 212, 206);
    border-width: 1px;
    margin-left: 5px;
    margin-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.Invoice p {
    font-size: 15px;
    margin: 0px;
}

.Invoice:hover {
    background-color: rgb(235, 235, 228);
}

#totalPrice {
    text-align: right;
    margin-right: 0px;
}

#number-positions {
    text-align: right;
}