.Customers {
    display: grid;
    grid-template-rows: 90px 50px 1fr 80px;
    background: rgb(233, 233, 227);
    box-shadow: 0 -10px 15px rgb(180, 180, 175) inset;
    overflow: hidden;
}

.Customers h1 {
    margin-left: 15px;
}

.Customers h1::selection {
    background: none;
}

.Customers h1:hover {
    cursor: default;
}

#SearchCustomer {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(20, 60, 145);
    border-radius: 5px;
    width: 270px;
    height: 25px;
    margin: 6px 6px 6px 15px;
    font-weight: 600;
}

#SearchCustomer {
    outline:0;
}

.newCustomer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#createCustomerButton-placeholder:focus {
    outline:0;
}

