.Car {
    border-bottom: solid;
    border-width: 1px;
    border-color: rgb(128, 120, 120);
    padding-bottom: 2px;
    padding-left: 2px;
    padding-top: 2px;
    height: 130px;
}

.Car:hover {
    background-color: rgb(209, 198, 198);
    background-size: cover;
    cursor: default;
}

.Car-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Car h4 {
    margin: 0;
}

.Car p {
    margin: 0;
    font-size: 13px;
}
