.InvoicePosition {
    display: grid;
    grid-template-columns: 40px 420px 100px 100px 100px 100px 50px 50px;
    grid-template-rows: 27px;
}

.InvoicePosition input {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(20, 60, 145);
    border-radius: 5px;
    height: 20px;
    margin: 0 5px 0 0;
    font-weight: 600;
    outline: 0;
    text-align: right;
}

input[id^='position-description-'] {
    text-align: left;
}

.InvoicePosition p {
    margin: 0;
}

p[id^='total-price-of-position-'] {
    text-align: right;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

button[id^='remove-button-'] {
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    justify-self: end;
}

button[id^='remove-button-']:hover {
    color: white;
    background-color: red;
}

button[id^='add-position-button-'] {
    font-size: 20px;
    color: rgb(20, 60, 145);
    border: 1px solid rgb(20, 60, 145);
    border-radius: 5px;

    margin-left: 4px;

    width: 25px;
    height: 25px;
}

button[id^='add-position-button-']:hover {
    background-color: rgb(20, 60, 145);
    color: white;
}
