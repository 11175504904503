
.CarButton button {
    font-size: 14px;
    font-weight: 700;
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;

    background-color: white;
    color: rgb(20, 60, 145);
    border: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgb(20, 60, 145);
    transition: transform;
    transition-duration: 0.3s;
}

.CarButton button:hover {
    transform: scale(1.1);
    background-color: rgb(20, 60, 145);
    color: white;
}