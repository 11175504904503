.Customer {
    border-bottom: solid;
    min-height: 20px;
    border-width: 1px;
    border-color: rgb(128, 120, 120);
    margin-right: 10px;
    margin-left: 10px;
}

.Customer:hover {
    background-color: rgb(209, 198, 198);
    background-size: cover;
    cursor: default;
}

.Customer h5 {
    margin: 0;
}

.Customer-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
    overflow: scroll;
}

.Customer-information {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.Customer p {
    font-size: 13px;
    margin: 0;
}
