.NewInvoice {
    display: grid;
    grid-template-rows: 40px 236px auto 140px 35px;
    height: 740px;
}

.NewInvoice p {
    margin: 0;
}

.NewInvoice h2 {
    text-align: center;
    border-radius: 10px 10px 0 0;
    background-color: rgb(20, 60, 145);
    background-size: cover;
    color: white;
    margin: 0;
    padding-top: 5px;
}

#Rechnungspositionen {
    border-bottom: solid;
    border-width: 1px;
    margin-bottom: 0;
}

.CarProperties {
    padding-left: 5px;
    padding-right: 5px;
}

.InvoiceData {
    padding-left: 5px;
    padding-right: 5px;
}

.InvoiceData h3 {
    margin-top: 4px;
}

.InvoiceProperties {
    display: grid;
    grid-template-columns: 40px 420px 100px 100px 100px 100px 40px 40px;
    grid-template-rows: 25px;
}

.InvoiceProperties h4 {
    margin: 0;
}

.Positions {
    border-bottom: solid;
    border-width: 1px;
    max-height: 235px;
    overflow: scroll;
}

#Menge, #Einzelpreis, #Rabatt {
    text-align: right;
    margin-right: 5px;
}

.SalaryInvoiceTotalWrapper {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;
}

.editable-invoice-total-wrapper {
    margin-left: auto;
    justify-content: right;
    text-align: right;
}

.editable-invoice-total {
    justify-content: right;
    text-align: right;
    display: grid;
    grid-template-columns: auto 110px;
}

#total {
    text-align: right;
    margin: 0;
}

.editable-invoice-total h3 {
    margin-top: 15px;
    margin-bottom: 5px;
}

.editable-invoice-bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    align-self: end;
    padding-top: 10px;
}

#no-vat {
    text-align: right;
    margin-right: 5px;
    font-weight: 600;
}
