.InvoiceList {
    overflow-y: scroll;
    display: grid;
    grid-template-rows: 23px auto;
    background-color: white;
    margin: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
}

.Properties {
    display: grid;
    grid-template-columns: 150px 100px 150px 160px auto;
    border-bottom: solid;
    border-color: rgb(212, 212, 206);
    border-width: 1px;
}

.List {
    overflow-y: scroll;
    /* margin-top: 10px; */
}

#totalPricePropertie {
    text-align: right;
    margin-right: 10px;
}